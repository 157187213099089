.ds_layout__sidebar {
    &--side-navigation {
        nav::before {
            background: #0065bd;
            display: block;
            content: "";
            height: 6px;
            margin-bottom: 10px;
        }
    }
}

.ds_category-list {
    &--expanded{
        padding-bottom: 24px;
        margin-left: 0px;
    }
}