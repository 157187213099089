.ds_page-header {
    margin-top: 0;
    margin-bottom: 12px;
  
    span {
      display: inline-block;
      margin-bottom: 8px;
    }
  }
  
  h1 {
    margin-bottom: 24px;
  }
  