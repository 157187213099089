/*------------------------------------*\
    LAYOUT
\*------------------------------------*/

@supports(display: grid) {
    /**
     * GRID KEY
     * c: content
     * f: feedback
     * h: header
     * l: list (e.g. category items, search results)
     * n: navigation (e.g. subpages of a publication or guide)
     * p: partner branding
     * s: sidebar
     */

    .#{$pl-prefix}layout {
        grid-template-areas:
            'h h' 's s' 'p p' 'c c' 'f f';
    }

    @include media-query(small) {
        .#{$pl-prefix}layout {
            grid-template-areas:
                'h h h h h h' 's s s s s s' 'p p p p p p' 'c c c c c c' 'f f f f f f';
        }
    }

    @include media-query(medium) {
        .#{$pl-prefix}layout {
            &--article {
                grid-template-areas:
                    'h h h h h h h h p p p p' 'c c c c c c c c s s s s' 'f f f f f f f f . . . .';
            }
        }
    }

    @include media-query(large) {
        .#{$pl-prefix}layout {
            &--article {
                grid-template-areas:
                    'h h h h h h h h . p p p' 'c c c c c c c c . s s s' 'f f f f f f f f . . . .';
            }
        }
    }
}
