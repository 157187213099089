.ds_category-header:not(.two-images) {
    .ds_category-header {
        &__media-container {
        }

        &__media {
            max-height: 270px;
            max-width: 240px;
        }
    }
}

.ds_category-header.two-images {
    grid-gap: 0px;

    .ds_category-header {
        &__media-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        &__media {
            max-height: 270px;
            max-width: 240px;
            margin: 0;

            &:first-child {
                margin-right: 2.5px
            }

            &:last-child {
                margin-left: 2.5px
            }
        }
    }
}

@supports(display: grid) {

    @include media-query(small) {
        .ds_category-header:not(.two-images) {
            .ds_category-header {
                &__media {
                    max-width: 220px;
                }
            }
        }
    }

    @include media-query(medium) {
        .ds_category-header.two-images {
            grid-gap: 32px 32px;

            .ds_category-header {
                &__header {
                    grid-column: 1 / span 5;
                }

                &__media-container {
                    grid-column: 6 / span 7;
                }

                &__media {
                    max-height: 260px;
                    max-width: 200px;
                }
            }
        }
    }

    @include media-query(large) {
        .ds_category-header.two-images {
            .ds_category-header {
                &__media {
                    max-height: 270px;
                    max-width: 240px;
                }
            }
        }
        .ds_category-header:not(.two-images) {
            .ds_category-header {
                &__media {
                    max-width: 300px;
                }
            }
        }
    }

    @include media-query(xlarge) {
        .ds_category-header.two-images {
            .ds_category-header {
                &__media {
                    max-height: 340px;
                    max-width: 300px;
                }
            }
        }
    }
}