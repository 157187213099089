@import "../breakpoints.scss";

.ds_site-header__navigation {
    border-top: 1px solid $ds_colour__border--light;
  }

  // Fix for currently selected nav element style rules
  .ds_site-navigation__link.ds_current:not(:focus):not(:hover) {
    background-color: $ds_colour__link--current__background;
  }

  .ds_site-navigation__link:hover:not(:focus), .ds_site-navigation__link.ds_current {
    border-left: 4px solid #0065bd;
  }

  .ds_site-header__language-switcher { 
    display: none;
  }

  @media #{$medium} {
    .ds_site-header__language-switcher { 
      display: inline-block;
      
      .ds_button {
        margin-top: 0px;
        margin-bottom: 0px; 
      }
    }

    .ds_site-navigation__link:hover:not(:focus), .ds_site-navigation__link.ds_current {
        border-left: none;
    }

    .ds_site-navigation .ds_site-navigation__link::after {
        background-color: currentColor;
        bottom: 0.5px;
        content: '';
        height: 1px;
        left: 50%;
        opacity: 0;
        position: absolute;
        right: 50%;
        transition: left 0.2s, right 0.2s, opacity 0.2s;
    }
      
    .ds_site-navigation .ds_site-navigation__link.ds_current:not(:focus):after {
        left: 0;
        opacity: 1;
        right: 0;
        height: 3px;
    }
  }

@media only screen and (max-width: 400px){
    .ds_site-header {
        &__content {
            .ds_site-branding {
                display: flex;
                flex-direction: column;
                align-items: normal
            }
        }
    }
    .ds_site-branding {

        &__title {
            margin-left: 0px;

            &:not(:first-child) {
                margin-left: 0px;
                margin-top: 10px;

                .ds_site-branding__link:before {
                    margin-top: -10px;
                }
            }
        }

        &__link {
            height: auto;
            width: 100%;

            &:before {
                background: #0065bd;
                bottom: 0;
                content: "";
                position: absolute;
                right: -12px;
                top: 0;
                width: 1px;
                pointer-events: none;
            }
        }
    }
}